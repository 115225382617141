.file-uploader-loader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.file-uploader-loader__text {
    margin-bottom: 12px;
    color: var(--c-gray-dark);
}

.file-uploader-loader__bar {
    width: 205px;
    height: 8px;
    background: var(--c-light-dark-dark);
}

.file-uploader-loader__thumb {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.file-uploader-loader__thumb::before,
.file-uploader-loader__thumb::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
}

.file-uploader-loader__thumb::before {
    width: 100%;
    height: 100%;
    background-color: var(--c-gray-dark);
    animation: loading 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

@keyframes loading {
    0%, 44%, 88.1%, 100% {
        transform-origin: left;
    }

    0%, 100%, 88% {
        transform: scaleX(0);
    }

    44.1%, 88% {
        transform-origin: right;
    }

    33%, 44% {
        transform: scaleX(1);
    }
}
