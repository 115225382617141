.task-page__status {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.task-page__geo {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.task-page__image-preview {
    position: relative;
}
.task-page__image-preview  .task-page__image-del-icon {
    background-color: white;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    position: absolute;
    right: 0;
}
.task-page__image-preview  .task-page__image-del-icon:hover {
    background-color: gray;
}

.task-page__image-preview img {
    border-radius: 1.5rem;
    max-height: 10000px; /* На случай неадекватно узкой картинки */
    width: 100%;
}
