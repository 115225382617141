.image-uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--c-gray-light-light);
    border-radius: 8px;
}

.image-uploader--dragging {
    border-color: var(--c-primary-dark);
    background: var(--c-primary-bg-12);
}

.image-uploader--dragging > i {
    font-size: 24px;
    color: var(--c-primary);
}

.image-uploader--error {
    border-color: var(--c-negative);
}


.image-uploader__control {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.image-uploader__control-text { display: none; }

.image-uploader__input-file { display: none; }

.image-uploader__error-message {
    color: var(--c-negative);
    text-align: center;
}

/*
@media (min-width: 768px) {
    .image-uploader__control-text {
        display: initial;
        margin-top: 12px;
    }
}
 */
